import * as Sentry from '@sentry/gatsby';

const ENVIRONMENT = process.env.SENTRY_ENVIRONMENT;
Sentry.init({
  environment: ENVIRONMENT,
  dsn: 'https://3709a881cd6c5540d789ef9213d631af@o4504377154338816.ingest.us.sentry.io/4507566533246976',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
