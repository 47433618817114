import React from 'react';
import ErrorBoundary from './src/components/error-boundary';

const ciEnvironment = process.env.CI_ENV === 'true';

if (process.env.MSW_ENABLED === 'true') {
  const { worker } = require('./src/mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

export { wrapRootElement } from './src/apollo/wrap-root-element';

export const wrapPageElement = ({ element }) => {
  if (ciEnvironment) {
    return <ErrorBoundary>{element}</ErrorBoundary>;
  }
  return element;
};
