// src/mocks/handlers.js
// eslint-disable-next-line import/no-extraneous-dependencies
import { http, HttpResponse } from 'msw';

export const handlers = [
  // Handles a POST /intallers request
  http.post(
    `${process.env.GATSBY_INSTALLERS_API_ENDPOINT}/installers`,
    async ({ request }) => {
      await request.json();
      return HttpResponse.json({ data: 'response data' });
    },
  ),
  // Handles a POST /contact request
  http.post(
    `${process.env.GATSBY_CONTACT_API_ENDPOINT}`,
    async ({ request }) => {
      const { name } = await request.json();
      if (name === 'error') {
        return HttpResponse.error();
      }
      return new HttpResponse(null, {
        status: 200,
      });
    },
  ),
];
